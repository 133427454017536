import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CHAIN, TonConnectButton, TonConnectUIProvider, useTonConnectUI } from "@tonconnect/ui-react";
import Section from '../components/section';
import CollectionCreateForm from '../components/collectionCreateForm';
import NftMintForm from '../components/nftMintForm';
import TonConnectBtn from '../components/connectBtn';
import { tonMainfestUrl } from '../config';
import { useTonConnect } from '../hooks/useTonConnect';
import TonInfo from '../components/info';
import WithdrawBtn from '../components/withdrawBtn';
import MintItemBtn from '../components/mintItemBtn';
import { useParams } from 'react-router-dom';
import Header from '../components/header';

const SingleCollectionPage = () => {

  const {network} = useTonConnect()
  const { collection } = useParams();

  if (!collection) {
    return (
      <div className="app">
           <div className="snftBody">
              <Header />
              <div className="snftContent">
              <Section>
                <h1>Invalid collection</h1>
              </Section>
              </div>
           </div>
      </div>
    );
  }

  return (
    <div className="app">
         <div className="snftBody">
            <Header />
            <div className="snftContent">
            <Section>
              <MintItemBtn collection={collection} />
            </Section>
            </div>
         </div>
    </div>
  );
};

export default SingleCollectionPage