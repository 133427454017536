import { useEffect, useState } from "react";
import { Address, beginCell, Cell, fromNano, toNano, OpenedContract } from "@ton/core";
import { useAsyncInitialize } from "./useAsyncInitialize";
import { useTonClient } from "./useTonCLient";
import { useTonConnect } from "./useTonConnect";
import { SimpleNftCollection } from "../wrappers/SimpleNFTCollection";

const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

export function useCollectionContract() {
    const { client } = useTonClient();
    const {wallet, sender} = useTonConnect();
    const [balance, setBalance] = useState<string | null>();

    const customCollection = (address: string) => {
        return useAsyncInitialize(async() => {
            if (!client || !wallet) return;

            console.log("Address: ", address)
    
            const contract: any = SimpleNftCollection.fromAddress(Address.parse(address));
    
            return client.open(contract) as OpenedContract<SimpleNftCollection>
        }, [client, wallet])
    }

    return {
        balance: balance,
        getMasterData: async (address: string) => {
            return new Promise((resolve, reject) => {
                try {
                    const data = customCollection(address)?.getGetMasterData();
                    resolve(data)
                } catch (e: any) {
                    resolve(null)
                }
            })

        },
        getNftAddressByIndex: (address: string, index: number) => {
            return customCollection(address)?.getGetNftAddressByIndex(BigInt(index))
        },
        getNftContent: (address: string, index: number) => {
            return customCollection(address)?.getGetNftContent(BigInt(index), new Cell({}))
        },
        mint: async (address: string) => {
            return new Promise(async (resolve, reject) => {
                if (!wallet) {
                    resolve(false);
                    return;
                }

                const contract = SimpleNftCollection.fromAddress(Address.parse(address));
                const opened = client?.open(contract) as OpenedContract<SimpleNftCollection>
                const masterData = await opened.getGetMasterData()
                console.log("Owner address: ", wallet, Address.parse(wallet))
                console.log("Important data: ", masterData);
                opened?.send(sender, {
                    value: masterData.price
                }, "Mint").then((result) => {
                    console.log("Result: ", result);
                    resolve(true)
                }).catch((e) => {
                    console.log("Error: ", e);
                    resolve(false)
                })
            })
        }
    }
}