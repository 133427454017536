import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CHAIN, TonConnectButton, TonConnectUIProvider, useTonConnectUI } from "@tonconnect/ui-react";
import Section from '../components/section';
import CollectionCreateForm from '../components/collectionCreateForm';
import NftMintForm from '../components/nftMintForm';
import TonConnectBtn from '../components/connectBtn';
import { tonMainfestUrl } from '../config';
import { useTonConnect } from '../hooks/useTonConnect';
import TonInfo from '../components/info';
import WithdrawBtn from '../components/withdrawBtn';
import MintItemBtn from '../components/mintItemBtn';
import { useParams } from 'react-router-dom';
import Header from '../components/header';

const CollectionCreatePage = () => {

  const {network, wallet } = useTonConnect();

  return (
    <div className="app">
         <div className="snftBody">
            <Header />
            <div className="snftContent">
            <Section>
              <h3>Create NFT collection:</h3>
                <CollectionCreateForm />
            </Section>
            </div>
         </div>
    </div>
  );
};

export default CollectionCreatePage