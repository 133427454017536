import { useEffect, useState } from "react";
import {
  Address,
  beginCell,
  fromNano,
  OpenedContract,
  toNano,
} from "@ton/core";
import {
  CollectionMintParams,
  SimpleNftMaster,
} from "../contracts/SimpleNFTMaster/tact_SimpleNFTMaster";
import { useAsyncInitialize } from "./useAsyncInitialize";
import { useTonClient } from "./useTonCLient";
import { masterTonContract } from "../config";
import { useTonConnect } from "./useTonConnect";
import {
  SimpleNftCollection,
  Withdraw,
} from "../contracts/SimpleNFTMaster/tact_SimpleNFTCollection";
import { getWrappedRoyaltyParams, makeCellForLink, UnwrappedRoyaltyParams } from "../utils/mint";
import { RoyaltyParams } from "../wrappers/SimpleNFTCollection";

const sleep = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export interface CollectionMintData {
   collectionDataUrl: string;
   itemDataUrl: string;
   item_price: number;
   mint_limit: number;
   owner_address: string;
   royaltyParams: UnwrappedRoyaltyParams
}

export function useMasterContract() {
  const { client } = useTonClient();
  const { wallet, sender } = useTonConnect();

  const creationTonPrice = "0.5";

  const masterContract = useAsyncInitialize(async () => {
    if (!client || !wallet) return;

    const contract: any = SimpleNftMaster.fromAddress(
      Address.parse(masterTonContract)
    );

    return client.open(contract) as OpenedContract<SimpleNftMaster>;
  }, [client, wallet]);

  return {
    contractAddress: masterContract?.address,
    collectionCreationPrice: creationTonPrice,
    newCollectionData: async () => {
      const index = await masterContract?.getGetNextCollectionIndex();
      console.log("Found index: ", index);
      const address = await masterContract?.getGetCollectionAddressByIndex(index || 0n);
      const strAddress = address?.toString();
      const result = {
        index,
        address: strAddress
      }
      return result;
    },
    mintCollection: async (data: CollectionMintData) => {
      return new Promise((resolve, reject) => {
        if (!wallet) {
          resolve(false);
          return;
        }

        const collectionContentCell = makeCellForLink(data.collectionDataUrl);
        const itemContentCell = makeCellForLink(data.itemDataUrl);
        const wrappedRoyaltyParams = getWrappedRoyaltyParams(data.royaltyParams)

        masterContract
          ?.send(
            sender,
            {
              value: toNano(creationTonPrice),
            },
            {
              $$type: "CollectionMintParams",
              queryId: 0n, 
              owner_address: Address.parse(data.owner_address), 
              collection_content: collectionContentCell,
              nft_individual_content_url: itemContentCell,
              nft_price: BigInt(data.item_price),
              mint_limit: BigInt(data.mint_limit),
              royalty_params: wrappedRoyaltyParams
            }
          )
          .then((result) => {
            console.log("Result: ", result);
            resolve(true);
          })
          .catch((e) => {
            console.log("Error: ", e);
            resolve(false);
          });
      });
    },
    withdraw: async (toAddress: string) => {
      return new Promise((resolve, reject) => {
        if (!wallet) {
          resolve(false);
          return;
        }

        masterContract
          ?.send(
            sender,
            {
              value: toNano("0.3"),
            },
            {
              $$type: "Withdraw",
              to: Address.parse(toAddress),
            }
          )
          .then((result) => {
            console.log("Result: ", result);
            resolve(true);
          })
          .catch((e) => {
            console.log("Error: ", e);
            resolve(false);
          });
      });
    },
  };
}
