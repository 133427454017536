import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CHAIN, TonConnectButton, TonConnectUIProvider, useTonConnectUI } from "@tonconnect/ui-react";
import Section from '../components/section';
import CollectionCreateForm from '../components/collectionCreateForm';
import NftMintForm from '../components/nftMintForm';
import TonConnectBtn from '../components/connectBtn';
import { tonMainfestUrl } from '../config';
import { useTonConnect } from '../hooks/useTonConnect';
import TonInfo from '../components/info';
import WithdrawBtn from '../components/withdrawBtn';
import MintItemBtn from '../components/mintItemBtn';
import PageLinkSection from '../components/pageLinkSection';

const AccountPage = () => {

  const {network} = useTonConnect()

  return (
    <div className="app mainPage">
         <div className="snftBody">
            <div className="snftHeader">
               <div className="snftLogo">
                  SimpleNFT app
               </div>
               <TonConnectButton />
            </div>
         </div>
    </div>
    )
}

export default AccountPage