import { Address, beginCell, Cell } from "@ton/core";
import { RoyaltyParams } from "../wrappers/SimpleNFTCollection";

export const OFFCHAIN_CONTENT_PREFIX = 0x01;

export interface UnwrappedRoyaltyParams {
  numenator: number;
  denumenator:number;
  destination: string
}

const data = {
  name: "Great win NFT", // The name of the NFT
  description: "My description", // A brief description of the NFT
  image:
    "https://bronze-cheerful-newt-626.mypinata.cloud/ipfs/QmTPSH7bkExWcrdXXwQvhN72zDXK9pZzH3AGbCw13f6Lwx/3.jpg", // URL pointing to the image or media representing the NFT
  attributes: [
    { trait_type: "Background", value: "Blue" }, // Example attributes (traits) of the NFT
    { trait_type: "Eyes", value: "Green" },
    { trait_type: "Hat", value: "Red Cap" },
  ],
};

function bufferToChunks(buff: Buffer, chunkSize: number) {
  const chunks: Buffer[] = [];
  while (buff.byteLength > 0) {
    chunks.push(buff.subarray(0, chunkSize));
    buff = buff.subarray(chunkSize);
  }
  return chunks;
}

export function makeCellForLink(link: string): Cell {
  return beginCell()
    .storeInt(OFFCHAIN_CONTENT_PREFIX, 8)
    .storeBuffer(Buffer.from(link))
    .endCell();
}

export function getWrappedRoyaltyParams(params: UnwrappedRoyaltyParams): RoyaltyParams {
  return ({
      $$type: "RoyaltyParams",
      numerator: BigInt(params.numenator),
      denominator: BigInt(params.denumenator),
      destination: Address.parse(params.destination)
  })
}

export function hexToString(hex: string): string {
  if (hex.startsWith('0x')) {
      hex = hex.slice(2);
  }

  let str = '';
  for (let i = 0; i < hex.length; i += 2) {
      const hexCode = hex.substring(i, i + 2);
      str += String.fromCharCode(parseInt(hexCode, 16));
  }
  return str;
}

function makeSnakeCell(data: Buffer): Cell {
  const chunks = bufferToChunks(data, 127);

  if (chunks.length === 0) {
    return beginCell().endCell();
  }

  if (chunks.length === 1) {
    return beginCell().storeBuffer(chunks[0]).endCell();
  }

  let curCell = beginCell();

  for (let i = chunks.length - 1; i >= 0; i--) {
    const chunk = chunks[i];

    curCell.storeBuffer(chunk);

    if (i - 1 >= 0) {
      const nextCell = beginCell();
      nextCell.storeRef(curCell);
      curCell = nextCell;
    }
  }

  return curCell.endCell();
}

function encodeOffChainContent(content: string) {
  let data = Buffer.from(content);
  const offChainPrefix = Buffer.from([0x01]);
  data = Buffer.concat([offChainPrefix, data]);
  return makeSnakeCell(data);
}

export const defaultMeta = encodeOffChainContent(JSON.stringify(data));
