import React, { useEffect, useState, Suspense, lazy } from "react";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import "./scss/app.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  CHAIN,
  TonConnectButton,
  TonConnectUIProvider,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import "./scss/app.scss";
import Section from "./components/section";
import CollectionCreateForm from "./components/collectionCreateForm";
import NftMintForm from "./components/nftMintForm";
import TonConnectBtn from "./components/connectBtn";
import { tonMainfestUrl } from "./config";
import { useTonConnect } from "./hooks/useTonConnect";
import TonInfo from "./components/info";
import WithdrawBtn from "./components/withdrawBtn";
import MintItemBtn from "./components/mintItemBtn";
import CollectionCreatePage from "./pages/createCollection";
import SingleCollectionPage from "./pages/collection";
import MainPage from "./pages/mainPage";
import AccountPage from "./pages/account";


const App = () => {
  const { network } = useTonConnect();

  return (
    <BrowserRouter>
        <Routes>
          <Route path="/account" element={<AccountPage />}/>
          <Route path="/collection/create" element={<CollectionCreatePage />}/>
          <Route path="/collection/:collection" element={<SingleCollectionPage />} />
          <Route path="/item/:item" element={<SingleCollectionPage />} />
          <Route path="/" element={<MainPage />} />
        </Routes>
    </BrowserRouter>
  );
};

export default App;
